import React, { useContext } from "react";
import { ShopContext } from "../../context/shop-context";
import { PRODUCTS } from "../../products";
import { CartItem } from "./cart-item";
import { useNavigate } from "react-router-dom";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

import "./cart.css";

export const Cart = () => {
  const { cartItems, getTotalCartAmount /*, checkout*/ } = useContext(ShopContext);
  const totalAmount = getTotalCartAmount();

  const navigate = useNavigate();

  const config = {
    public_key: 'FLWPUBK-b976cd97f80b60d6861b6b062689594e-X',
    tx_ref: Date.now(),
    amount: totalAmount,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
    email: 'user@gmail.com',
    phone_number: '070********',
    name: 'john doe',
    },
    customizations: {
    title: 'Webcrawler',
    description: 'Payment for items in cart',
    logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
};

  const handleFlutterPayment = useFlutterwave(config);


  return (
    <div className="cart">
      <div>
        <h1>Your Cart Items</h1>
      </div>
      <div className="cart">
        {PRODUCTS.map((product) => {
          if (cartItems[product.id] !== 0) {
            return <CartItem data={product} />;
          }
        })}
      </div>

      {totalAmount > 0 ? (
        <div className="checkout">
          <p> Subtotal: {totalAmount} </p>
          <button onClick={() => navigate("/")}> Continue Shopping </button>
          <button
            onClick={() => {
              handleFlutterPayment({
                  callback: (response) => {
                  console.log(response);
                      closePaymentModal() // this will close the modal programmatically
                  },
                  onClose: () => {},
              });
              }}

            /*onClick={() => {
              checkout();
              navigate("/checkout");
            }}
            /*
            onClick={() => {
              checkout();
              window.location.href = "https://flutterwave.com/pay/m0d7wdxxrusn";
            }}*/
          >
            {" "}
            Checkout{" "}
          </button>
        </div>
      ) : (
        <h1> Your Shopping Cart is Empty</h1>
      )}
    </div>
  );
};
