import product1 from "./assets/products/1.jpeg";
import product2 from "./assets/products/2.jpeg";
import product3 from "./assets/products/3.jpeg";
import product4 from "./assets/products/4.jpeg";
import product5 from "./assets/products/5.jpeg";
import product6 from "./assets/products/6.jpeg";
import product7 from "./assets/products/7.jpeg";
import product8 from "./assets/products/8.jpeg";

export const PRODUCTS = [
  {
    id: 1,
    productName: "Burger",
    price: 200,
    productImage: product1,
  },
  {
    id: 2,
    productName: "Yougurt",
    price: 500,
    productImage: product2,
  },
  {
    id: 3,
    productName: "Peanut",
    price: 100,
    productImage: product3,
  },
  {
    id: 4,
    productName: "Salted Butter",
    price: 100,
    productImage: product4,
  },
  {
    id: 5,
    productName: "Milk",
    price: 100,
    productImage: product5,
  },
  {
    id: 6,
    productName: "Vitamin E",
    price: 250,
    productImage: product6,
  },
  {
    id: 7,
    productName: "Soil Potatoe",
    price: 120,
    productImage: product7,
  },
  {
    id: 8,
    productName: "Chips",
    price: 320,
    productImage: product8,
  },
];
